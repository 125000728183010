import styled from 'styled-components';

export const Head = styled.div`
  height: 600px;
  padding: 60px 0 0 0;
  text-align: center;
  background: url(${(props) => props.$backgroundImage}) no-repeat top center;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.7);
  @media (max-width: 767px) {
    height: 500px;
    padding: 35px 35px 35px 35px;
    background-size: 1300px auto;
  }
`;

export const HeadLogo = styled.img`
  margin-bottom: 40px;
  width: 100%;
  max-width: 350px;
  height: auto;
  @media (max-width: 767px) {
    max-width: 250px;
    width: 100%;
  }
`;

export const HeaderH1 = styled.h1`
  margin-bottom: 45px;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 63px;
  line-height: 63px;
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  margin: 0;
  font-weight: normal;
  @media (max-width: 767px) {
    font-size: 45px;
    line-height: 45px;
  }
  i {
    display: block;
    margin: 0 !important;
    padding: 0;
    font-size: 53px;
    line-height: 53px;
    color: #fec119;
    font-family: 'Figtree-Black', arial, sans-serif;
    text-transform: uppercase;
    font-style: normal !important;
    @media (max-width: 767px) {
      font-size: 38px;
      line-height: 38px;
    }
  }
  code {
    display: block;
    margin-bottom: 30px;
    font-size: 34px;
    line-height: 34px;
    color: #fec119;
    font-family: 'Figtree-Black', arial, sans-serif;
    @media (max-width: 767px) {
      margin-bottom: 25px;
      font-size: 25px;
      line-height: 25px;
    }
  }
  u {
    padding-top: 5px;
    border-top: 2px solid #fec119;
    text-decoration: none !important;
  }
`;
